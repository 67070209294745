<template>
  <div id="home-Left">
    <!-- 新的页面 -->
    <div id="home-left-data1" class="box-shadow"></div>

    <div id="home-left-data2" class="box-shadow"></div>

    <div id="home-left-data3" class="box-shadow"></div>
  </div>
</template>
<script>
import { ALL_APPS_MENU } from '@/store/mutation-types'
import elementResizeDetectorMaker from 'element-resize-detector'
export default {
  name: 'TestBaiDu',
  data() {
    return {
      chart1: null,
      chart2: null,
      chart3: null
    }
  },
  // 注册组件
  components: {},
  created() {},
  mounted() {
    this.init()
    // this.allMenus = Vue.ls.get(ALL_APPS_MENU)[0].menu
  },
  methods: {
    init() {
      // this.drawEchart1()
      // this.drawEchart2()
      // this.drawEchart3()
    },

    // 绘制图表
    drawEchart1() {
      if (this.chart1) {
        this.chart1.clear()
      } else {
        this.chart1 = this.$echarts.init(document.getElementById('echarts1'))
      }
      const erd = elementResizeDetectorMaker()
      erd.listenTo(document.getElementById('home-left-data2'), () => {
        this.chart1.resize()
      })

      let data = [
        { name: '1月', value: 400 },
        { name: '2月', value: 600 },
        { name: '3月', value: 800 },
        { name: '4月', value: 540 },
        { name: '5月', value: 720 },
        { name: '6月', value: 200 },
        { name: '7月', value: 210 },
        { name: '8月', value: 550 },
        { name: '9月', value: 900 },
        { name: '10月', value: 439 },
        { name: '11月', value: 670 },
        { name: '12月', value: 520 }
      ]
      let option = {
        grid: {
          right: 10,
          top: '15%'
        },
        tooltip: {
          trigger: 'axis'
        },
        xAxis: {
          type: 'category',
          axisTick: {
            alignWithLabel: true
          },
          data: data.map(x => {
            return x.name
          })
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            textStyle: {
              color: '#666'
            }
          },
          nameTextStyle: {
            color: '#666',
            fontStyle: 12
          },
          splitLine: {
            lineStyle: {
              type: 'dashed',
              color: '#818C98'
            }
          },
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          name: '(万)'
        },
        series: [
          {
            name: '收入统计',
            type: 'line',
            stack: 'Total',
            smooth: true,
            symbol: 'emptyCircle',
            symbolSize: 7,
            lineStyle: {
              width: 2,
              color: '#0E9CFF'
            },
            itemStyle: {
              color: '#0E9CFF', //拐点颜色
              // borderColor: "#0000ff", //拐点边框颜色
              borderWidth: 2 //拐点边框大小
            },
            areaStyle: {
              opacity: 0.12,
              color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: '#0E9CFF'
                },
                {
                  offset: 1,
                  color: '#ffffff'
                }
              ])
            },
            emphasis: {
              focus: 'series'
            },
            data: data
          }
        ]
      }

      option && this.chart1.setOption(option)
    },
    // 项目状态雷达
    drawEchart2() {
      if (this.chart2) {
        this.chart2.clear()
      } else {
        this.chart2 = this.$echarts.init(document.getElementById('projectStatus'))
      }
      const erd = elementResizeDetectorMaker()
      erd.listenTo(document.getElementById('home-left-data3'), () => {
        this.chart2.resize()
      })

      let data = this.mergedArray.map(ele => {
        return {
          name: ele.value,
          value: ele.count
        }
      })

      let maxData = this.mergedArray.map(ele => {
        return {
          name: ele.value,
          max: 5
        }
      })

      let option = {
        tooltip: {},
        radar: {
          indicator: maxData,
          radius: '70%', // 设置雷达图的半径，可以是比例值或具体数值，如：radius: 200
          center: ['50%', '50%'], // 设置雷达图的中心位置坐标
          areaStyle: {
            // 设置雷达图的值所围成区域的背景色
            color: 'rgba(58, 142, 255, 0.5)' // 设置背景色的颜色
          }
        },
        series: [
          {
            name: '项目状态',
            type: 'radar',
            symbol: 'circle', // 圆点的图形
            data: [
              {
                value: data.map(ele => ele.value),
                name: '项目状态'
              }
            ]
          }
        ]
      }

      option && this.chart2.setOption(option)
    },
    // 人员资质证书
    drawEchart3() {
      let data = [
        { name: '1月', value: 400 },
        { name: '2月', value: 600 },
        { name: '3月', value: 800 },
        { name: '4月', value: 540 },
        { name: '5月', value: 720 },
        { name: '6月', value: 200 },
        { name: '7月', value: 210 },
        { name: '8月', value: 550 },
        { name: '9月', value: 900 },
        { name: '10月', value: 439 },
        { name: '11月', value: 670 },
        { name: '12月', value: 520 }
      ]
      if (this.chart3) {
        this.chart3.clear()
      } else {
        this.chart3 = this.$echarts.init(document.getElementById('projectPerson'))
      }
      const erd = elementResizeDetectorMaker()
      erd.listenTo(document.getElementById('home-left-data3'), () => {
        this.chart3.resize()
      })
      let chartData = [
        {
          name: '注册监理工程师',
          value: 2
        },
        {
          name: '监理工程师',
          value: 15
        },
        {
          name: '其他',
          value: 6
        }
      ]
      let option = {
        //修改hover上去显示的样式
        tooltip: {
          trigger: 'item',
          formatter: function(params, ticket, callback) {
            let color = params.color // 获取对应扇形的颜色值
            let name = params.name // 获取对应扇形的名称
            let value = params.value // 获取对应扇形的数值
            return (
              '<div style="display: flex; align-items: center;">' +
              '<div style="width: 12px; height: 12px; border-radius: 50%; background-color: ' +
              color +
              ';"></div>' +
              '<div style="margin-left: 10px;">' +
              name +
              ': ' +
              value +
              ' (' +
              params.percent +
              '%)' +
              '</div>' +
              '</div>'
            )
          }
        },
        color: ['#006cff', '#60cda0', '#ed8884', '#ff9f7f', '#0096ff', '#9fe6b8', '#32c5e9', '#1d9dff'],
        series: [
          {
            name: '',
            type: 'pie',
            radius: ['20%', '70%'], //设置hover上去突出圆的范围
            center: ['45%', '50%'], //设置圆的位置
            roseType: 'radius',
            data: chartData,
            // 修饰饼形图文字相关的样式 label对象
            label: {
              fontSize: 10,
              show: true
            },
            // 修饰引导线样式
            labelLine: {
              show: true,
              // 连接到图形的线长度
              length: 10,
              // 连接到文字的线长度
              length2: 10
            }
          }
        ]
      }

      option && this.chart3.setOption(option)
    },
    drawEchart4() {
      if (this.chart2) {
        this.chart2.clear()
      } else {
        this.chart2 = this.$echarts.init(document.getElementById('echarts2'))
      }
      const erd = elementResizeDetectorMaker()
      erd.listenTo(document.getElementById('home-left-data3'), () => {
        this.chart2.resize()
      })
      let option = {
        title: {
          text: 'Referer of a Website',
          subtext: 'Fake Data',
          left: 'center'
        },
        tooltip: {
          trigger: 'item'
        },
        legend: {
          orient: 'vertical',
          left: 'left'
        },
        series: [
          {
            name: 'Access From',
            type: 'pie',
            radius: '50%',
            data: [
              { value: 1048, name: 'Search Engine' },
              { value: 735, name: 'Direct' },
              { value: 580, name: 'Email' },
              { value: 484, name: 'Union Ads' },
              { value: 300, name: 'Video Ads' }
            ],
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            }
          }
        ]
      }

      option && this.chart2.setOption(option)
    },
    objectSpanMethod({ row, column, rowIndex, columnIndex }) {
      if (columnIndex === 0) {
        if (rowIndex % 2 === 0) {
          return {
            rowspan: 2,
            colspan: 1
          }
        } else {
          return {
            rowspan: 0,
            colspan: 0
          }
        }
      }
    }
  }
}
</script>
<style lang="scss" scoped>
#home-Left {
  width: 100%;
  padding-right: 20px;

  #home-left-data1,
  #home-left-data2,
  #home-left-data3 {
    width: 100%;
    padding: 0.125rem;
    margin-bottom: 0.21rem;
    background: #ffffff;
    border-radius: 0.05rem;
    opacity: 1;
    position: relative;
  }

  #home-left-data1 {
    height: 2.925rem;
    padding: 15px;
    background: linear-gradient(112deg, #2473ff 0%, #5c99ff 100%);
  }

  #home-left-data2 {
    height: 3.75rem;

    #echarts1 {
      width: 100%;
      height: 3.25rem;
    }
  }

  #home-left-data3 {
    display: flex;
    justify-content: space-between;
    background: none;
    padding: 0;
  }
}

.box-shadow:hover {
  box-shadow: 0 0 11px 0 rgba(0, 0, 0, 0.15);
}
</style>
