<template>
  <div id="home-Right">
    <div id="home-right-box1" class="box-shadow"></div>

    <div id="home-right-box2" class="box-shadow"></div>
  </div>
</template>
<script>
import { ALL_APPS_MENU } from '@/store/mutation-types'
import elementResizeDetectorMaker from 'element-resize-detector'

import Vue from 'vue'
export default {
  data() {
    return {
      chart1: null,
      chart2: null
    }
  },
  // 注册组件
  components: {},
  created() {},
  mounted() {
    this.init()
    // const all_app_menu = Vue.ls.get(ALL_APPS_MENU)[0].menu
  },
  methods: {
    init() {}
  }
}
</script>

<style lang="scss" scoped>
#home-Right {
  width: 100%;
  // height: 9rem;

  #home-right-box1 {
    width: 100%;
    height: 2.925rem;
    background: #ffffff;
    padding: 0.125rem;
    border-radius: 0.05rem;
    opacity: 1;
    margin-bottom: 0.21rem;
  }

  #home-right-box2 {
    width: 100%;
    padding: 0.125rem;
    height: 7.1625rem;
    background: #ffffff;
    border-radius: 0.11rem;
    opacity: 1;
    margin-bottom: 0.21rem;
  }
}

.box-shadow:hover {
  box-shadow: 0 0 11px 0 rgba(0, 0, 0, 0.15);
}
</style>
