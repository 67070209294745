<template>
  <div>
    <el-row>
      <el-col :span="16">
        <companyLeft />
      </el-col>
      <el-col :span="8">
        <companyRight />
      </el-col>
    </el-row>
  </div>
</template>

<script>
import companyLeft from './company/companyLeft.vue'
import companyRight from './company/companyRight.vue'
export default {
  data() {
    return {
      key: ''
    }
  },
  components: {
    companyLeft,
    companyRight
  }
}
</script>

<style lang="scss" scoped></style>
